export const marketingNotificationDisplayHelper = (
  isNews,
  forDealers,
  forSuppliers,
  forDealersBonusProgramMembers,
  forSuppliersBonusProgramMembers
) => {
  switch (true) {
    case forDealers &&
      isNews &&
      forSuppliers &&
      forDealersBonusProgramMembers &&
      forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для всех дилеров'
    case forSuppliersBonusProgramMembers &&
      !isNews &&
      !forSuppliers &&
      !forDealers &&
      !forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров, которые не являются поставщиками и участвуют в бонусной программе'
    case forSuppliersBonusProgramMembers &&
      !isNews &&
      !forSuppliers &&
      !forDealers &&
      !forDealersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров, которые  являются поставщиками и участвуют в бонусной программе'
    case forSuppliers &&
      !isNews &&
      !forDealers &&
      !forDealersBonusProgramMembers &&
      !forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров, которые являются поставщиками и не участвуют в бонусной программе'
    case isNews &&
      !forSuppliers &&
      !forDealers &&
      !forDealersBonusProgramMembers &&
      !forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление не будет показано никому, т.к. не выбрано ни одного флага: для показа дилерам или поставщикам'
    case isNews &&
      forSuppliers &&
      !forDealers &&
      !forDealersBonusProgramMembers &&
      !forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров, которые  являются поставщиками и не участвуют в бонусной программе'
    case isNews &&
      forSuppliersBonusProgramMembers &&
      !forDealers &&
      !forSuppliers &&
      !forDealersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров, которые являются поставщиками и участвуют в бонусной программе'
    case isNews &&
      forDealers &&
      !forSuppliers &&
      !forDealersBonusProgramMembers &&
      !forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров, которые не являются поставщиками и не участвуют в бонусной программе'
    case isNews &&
      forDealersBonusProgramMembers &&
      !forDealers &&
      !forSuppliers &&
      !forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров, которые не являются поставщиками и участвуют в бонусной программе'
    case forDealersBonusProgramMembers &&
      forSuppliersBonusProgramMembers &&
      !forDealers &&
      !forSuppliers &&
      !isNews:
      return 'Маркетинговое уведомление будет показано для всех дилеров и поставщиков, которые являются участниками бонусной программы'
    case forDealersBonusProgramMembers &&
      forSuppliersBonusProgramMembers &&
      isNews &&
      !forDealers &&
      !forSuppliers:
      return 'Маркетинговое уведомление будет показано для всех дилеров и поставщиков, которые являются участниками бонусной программы'
    case !forDealersBonusProgramMembers &&
      !forSuppliersBonusProgramMembers &&
      isNews &&
      forDealers &&
      forSuppliers:
      return 'Маркетинговое уведомление будет показано для всех дилеров и поставщиков, которые не являются участниками бонусной программы'
    case !forDealersBonusProgramMembers &&
      !forSuppliersBonusProgramMembers &&
      !isNews &&
      forDealers &&
      forSuppliers:
      return 'Маркетинговое уведомление будет показано для всех дилеров и поставщиков, которые не являются участниками бонусной программы'
    case !isNews &&
      forDealersBonusProgramMembers &&
      forDealers &&
      forSuppliers &&
      forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для всех дилеров'
    case isNews &&
      forDealersBonusProgramMembers &&
      forDealers &&
      forSuppliers &&
      forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для всех дилеров'
    case isNews &&
      forDealersBonusProgramMembers &&
      forDealers &&
      forSuppliers &&
      !forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров, дилеров участников бонусной программы, дилеров, которые являются поставщиками и не участвуют в бонусной программе'
    case !isNews &&
      forDealersBonusProgramMembers &&
      forDealers &&
      forSuppliers &&
      !forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров, дилеров участников бонусной программы, дилеров, которые являются поставщиками и не участвуют в бонусной программе'
    case !isNews &&
      !forDealersBonusProgramMembers &&
      forDealers &&
      forSuppliers &&
      forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров, дилеров, которые являются поставщиками, дилеров, которые являются поставщиками и участвуют в бонусной программе'
    case isNews &&
      !forDealersBonusProgramMembers &&
      forDealers &&
      forSuppliers &&
      forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров, дилеров, которые являются поставщиками, дилеров, которые являются поставщиками и участвуют в бонусной программе'
    case !isNews &&
      forDealersBonusProgramMembers &&
      forDealers &&
      !forSuppliers &&
      forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров, для дилеров, которые участвуют в бонусной программе и для дилеров, которые являются поставщиками и участвуют в бонусной программе'
    case !isNews &&
      forDealersBonusProgramMembers &&
      forDealers &&
      !forSuppliers &&
      !forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для всех дилеров, которые не являются поставщиками'
    case isNews &&
      forDealersBonusProgramMembers &&
      forDealers &&
      !forSuppliers &&
      !forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для всех дилеров, которые не являются поставщиками'
    case isNews &&
      forDealersBonusProgramMembers &&
      forDealers &&
      !forSuppliers &&
      forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров, для дилеров участников бонусной программы, для дилеров, которые являются поставщиками'
    case isNews &&
      !forDealersBonusProgramMembers &&
      !forDealers &&
      forSuppliers &&
      forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано всем дилерам, которые являются поставщиками'
    case !isNews &&
      !forDealersBonusProgramMembers &&
      !forDealers &&
      forSuppliers &&
      forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано всем дилерам, которые являются поставщиками'
    case !isNews &&
      forDealersBonusProgramMembers &&
      !forDealers &&
      forSuppliers &&
      forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров участников бонусной программы, для дилеров, которые являются поставщиками и для дилеров, которые являются поставщиками и участвуют в бонусной программе'
    case isNews &&
      forDealersBonusProgramMembers &&
      !forDealers &&
      forSuppliers &&
      forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров участников бонусной программы, для дилеров, которые являются поставщиками и для дилеров, которые являются поставщиками и участвуют в бонусной программе'
    case isNews &&
      forDealersBonusProgramMembers &&
      !forDealers &&
      forSuppliers &&
      !forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров участников бонусной программы, и для дилеров, которые являются поставщиками, но не участвуют в бонусной программе'
    case !isNews &&
      forDealersBonusProgramMembers &&
      !forDealers &&
      forSuppliers &&
      !forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров участников бонусной программы, и для дилеров, которые являются поставщиками, но не участвуют в бонусной программе'
    case isNews &&
      !forDealersBonusProgramMembers &&
      forDealers &&
      !forSuppliers &&
      forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров, которые не участвуют в бонусной программе и для дилеров, которые являются поставщиками и участвуют в бонусной программе'
    case !isNews &&
      !forDealersBonusProgramMembers &&
      forDealers &&
      !forSuppliers &&
      forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров, которые не участвуют в бонусной программе и для дилеров, которые являются поставщиками и участвуют в бонусной программе'
    case !isNews &&
      forDealersBonusProgramMembers &&
      !forDealers &&
      !forSuppliers &&
      !forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров, которые не являются поставщиками и участвуют в бонусной программе'
    case !isNews &&
      !forDealersBonusProgramMembers &&
      forDealers &&
      !forSuppliers &&
      !forSuppliersBonusProgramMembers:
      return 'Маркетинговое уведомление будет показано для дилеров, которые не являются поставщиками и не участвуют в бонусной программе'
    default:
      return 'Маркетинговое уведомление не будет показано никому'
  }
}
